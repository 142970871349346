import client from './../../http/client'

import { apiUrl } from '../../../config/index'

const token = () => {
  return localStorage.getItem('access_token')
}

export const getDashboard = () =>
  client.get(apiUrl + '/api/dashboard-v2', {
    headers: {
      Authorization: 'Bearer ' + token(),
    },
  })
