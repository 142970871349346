import { useQuery } from '@tanstack/react-query'
import { getDashboard } from '../../api/dashboard/dashboard'

export const useQueryGetDashboardData = () => {
  return useQuery({
    queryKey: ['dashboard'],
    queryFn: getDashboard,
    keepPreviousData: true,
    refetchInterval: 15 * 60 * 1000,
  })
}
